@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), url('assets/fonts/Pretendard-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), url('assets/fonts/Pretendard-ExtraBold.woff') format('woff');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), url('assets/fonts/Pretendard-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), url('assets/fonts/Pretendard-SemiBold.woff') format('woff');
  font-weight: 600;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), url('assets/fonts/Pretendard-Medium.woff') format('woff');
  font-weight: 500;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), url('assets/fonts/Pretendard-Regular.woff') format('woff');
  font-weight: 400;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), url('assets/fonts/Pretendard-Light.woff') format('woff');
  font-weight: 300;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), url('assets/fonts/Pretendard-ExtraLight.woff') format('woff');
  font-weight: 200;
}
@font-face {
  font-family: 'Pretendard';
  src: local('Pretendard'), url('assets/fonts/Pretendard-Thin.woff') format('woff');
  font-weight: 100;
}

body {
  font-family: 'Pretendard', sans-serif !important;
  font-display: swap;
  font-style: normal;
}
